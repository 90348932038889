import store from "@/core/services/store";
import { UPDATE_LINE_ITEM_STATE } from "@/core/services/store/line.item.module";

class ManageLineItem {
  alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  performCalculation(attribute) {
    let lineItem = attribute.dbLineItem;
    let discountValue = parseFloat(attribute.discount_value);
    let discountType = parseInt(
      attribute.discount_type
    ); /* 2 => Percent, 1 => Amount*/
    let discountAmount = parseFloat(attribute.discount_amount);
    let taxAmount = parseFloat(attribute.tax_amount);
    let taxValue = parseFloat(attribute.tax_value);
    let taxActive = parseInt(attribute.tax_active);
    let adjustment = parseFloat(attribute.adjustment);

    let subTotal = lineItem.reduce((acc, row) => {
      return acc + parseFloat(row?.rate ?? 0) * parseInt(row?.quantity ?? 0);
    }, 0);

    store.commit(UPDATE_LINE_ITEM_STATE, {
      key: "dbCalculation.subtotal",
      value: parseFloat(subTotal),
    });

    if (discountType == 1) {
      discountAmount = discountValue;
    }

    if (discountType == 2) {
      discountAmount = (parseFloat(subTotal) * discountValue) / 100;
    }

    subTotal = subTotal - discountAmount;

    taxAmount = 0;

    if (taxActive == 1) {
      taxAmount = (subTotal * taxValue) / 100;
    }

    subTotal = subTotal + taxAmount;

    subTotal = subTotal + adjustment;

    store.commit(UPDATE_LINE_ITEM_STATE, {
      key: "dbCalculation.discount_amount",
      value: parseFloat(discountAmount),
    });
    store.commit(UPDATE_LINE_ITEM_STATE, {
      key: "dbCalculation.tax_amount",
      value: parseFloat(taxAmount),
    });
    store.commit(UPDATE_LINE_ITEM_STATE, {
      key: "dbCalculation.total",
      value: parseFloat(subTotal),
    });

    console.log(attribute, "performCalculation");
  }
}

export default ManageLineItem;
