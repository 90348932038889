import ApiService from "@/core/services/api.service";
import ManageLineItem from "@/core/plugins/line.item.plugin";
import ObjectPath from "object-path";
import { cloneDeep } from "lodash";

const lineItemInstance = new ManageLineItem();

const alphabets = lineItemInstance.alphabets;

// action types
export const PERFORM_CALCULATION = "LtxPerformCalculation";
export const CLEAR_LINE_ITEM = "LtxClearLineItem";
export const CLEAR_DIALOG_LINE_ITEM = "LtxClearDialogLineItem";
export const DELETE_LINE_ITEM = "LtxDeleteLineItem";
export const SET_SELECTED_LINE_ITEM = "LtxSetSelectedLineItem";
export const UPDATE_LINE_ITEM_STATE = "LtxUpdateLineItemState";
export const GET_LINE_ITEM = "LtxGetLineItem";
export const SET_LINE_ITEM = "LtxSetLineItem";
export const SET_CATEGORY = "LtxSetLineItemCategory";
export const SET_LOADING = "LtxSetLineItemLoading";

// mutation types
export default {
  state: {
    dbLineItem: [],
    dbCategories: [],
    dbSelectedLineItem: [],
    dbAlphabets: alphabets,
    dbLoading: false,
    dbDiscountTypeList: [
      { type: "%", value: 2 },
      { type: "$", value: 1 },
    ],
    dbCalculation: {
      subtotal: 0,
      discount_amount: 0,
      discount_type: 1,
      discount_value: 0,
      tax_amount: 0,
      tax_value: 8,
      tax_active: 0,
      adjustment: 0,
      total: 0,
    },
  },
  getters: {
    liDiscountTypeList(state) {
      return state.dbDiscountTypeList;
    },
    liCalculation(state) {
      return state.dbCalculation;
    },
    liLoading(state) {
      return state.dbLoading;
    },
    liLineItem(state) {
      return state.dbLineItem;
    },
    liSelectedLineItem(state) {
      return state.dbSelectedLineItem;
    },
    liCategories(state) {
      return state.dbCategories;
    },
    liAlphabets(state) {
      return state.dbAlphabets;
    },
  },
  actions: {
    [PERFORM_CALCULATION](context) {
      const dbCalculation = {
        ...context.state.dbCalculation,
        dbLineItem: context.state.dbSelectedLineItem,
      };
      lineItemInstance.performCalculation(dbCalculation);
    },
    [GET_LINE_ITEM](context, payload) {
      context.commit(SET_LOADING, true);
      context.commit(SET_CATEGORY, []);
      context.commit(SET_LINE_ITEM, []);
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.query("product/find-line-items", payload)
          .then((data) => {
            context.commit(SET_CATEGORY, data?.data?.data?.categories ?? []);
            context.commit(SET_LINE_ITEM, data?.data?.data?.line_items ?? []);
            resolve(true);
          })
          .catch(() => {
            context.commit(SET_CATEGORY, []);
            context.commit(SET_LINE_ITEM, []);
            reject(false);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [SET_SELECTED_LINE_ITEM](context, payload) {
      const preSelected =
        context?.state?.dbSelectedLineItem?.concat(payload) ?? [];
      context.commit(SET_SELECTED_LINE_ITEM, preSelected);
      context.dispatch(PERFORM_CALCULATION);
    },
    [DELETE_LINE_ITEM](context, payload) {
      const preSelected =
        context?.state?.dbSelectedLineItem?.filter(
          (row) => row.uuid != payload
        ) ?? [];
      context.commit(SET_SELECTED_LINE_ITEM, preSelected);
      context.dispatch(PERFORM_CALCULATION);
    },
  },
  mutations: {
    [UPDATE_LINE_ITEM_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [CLEAR_LINE_ITEM](state) {
      state.dbLineItem = [];
      state.dbCategories = [];
      state.dbSelectedLineItem = [];
      state.dbLoading = false;
      state.dbCalculation = {
        subtotal: 0,
        discount_amount: 0,
        discount_type: 1,
        discount_value: 0,
        tax_amount: 0,
        tax_value: 8,
        tax_active: 0,
        adjustment: 0,
        total: 0,
      };
    },
    [CLEAR_DIALOG_LINE_ITEM](state) {
      state.dbLineItem = [];
      state.dbCategories = [];
    },
    [SET_SELECTED_LINE_ITEM](state, payload) {
      state.dbSelectedLineItem = cloneDeep(payload);
    },
    [SET_LOADING](state, payload) {
      state.dbLoading = cloneDeep(payload);
    },
    [SET_LINE_ITEM](state, payload) {
      state.dbLineItem = cloneDeep(payload);
    },
    [SET_CATEGORY](state, payload) {
      state.dbCategories = cloneDeep(payload);
    },
  },
};
